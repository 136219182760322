import api from "../../utils/api"
import api3rd from "../../utils/api3rd"
import router from "../../router"

export async function get_text(id, payload) {
  // return api.get(payload.plan_id?`/api/v1/mp/inquiries/session/articles/${payload.id}?plan_id=${payload.plan_id}&plan_type=${payload.plan_type}`:
  //   `/api/v1/mp/inquiries/session/articles/${payload.id}`)
  return api.get(`/api/v1/mp/inquiries/session/articles/${id}`, { params: payload })
}

/**
 * 查询Q&A
 * @param {*} payload
 * @returns
 */
export async function get_wordslots_tips(payload) {
  return api.post(`/api/v1/mp/luohu/wordslots/info`, payload)
}

/**
 * 查询处方详情
 * @param {*} payload
 * @returns
 */
export async function get_event_prescriptions_reports(payload) {
  return api.get(`/api/v1/mp/prescriptions/reports/${payload}`)
}

/**
 * 获取检验报告
 * @param {*} payload
 * @returns
 */
export async function get_laboratories_reports(payload) {
  return api.get(`/api/v1/mp/inquiries/session/laboratories/`, { params: payload })
}

/**
 * 检验报告详情
 * @param {*} payload
 * @returns
 */
export async function get_laboratories_reports_detail(payload) {
  return api.get(`/api/v1/mp/inquiries/session/laboratories/items`, { params: { report_id: payload } })
}

/**
 * 发送已阅读标签
 * @param {*} payload
 * @returns
 */
export async function post_readed_tags(payload) {
  if (router.app.$route.query.source === "yunying") return
  return api.post(`/api/v1/mp/tags`, payload)
}

/**
 * 获取检查报告
 * @param {*} payload
 * @returns
 */
export async function get_examines_reports(payload) {
  return api.get(`/api/v1/mp/examines/reports`, { params: { report_id: payload } })
}

/**
 * mock接口
 * @param {*} payload
 * @returns
 */
export async function order_group_datail(payload) {
  return api.get(`/api/v1/mp/datacenter/recipes`, { params: { recipe_id: payload } })
}

/**
 * get
 * @param {*} payload
 * @returns
 */
export async function get_order_id(payload) {
  return api.post(`/api/v1/mp/orders/`, payload)
}

/**
 * 埋点
 * @param {*} payload
 * @returns
 */
export async function articles_actions(payload) {
  if (router.app.$route.query.source === "yunying") return
  return api.post(`/api/v1/mp/articles/actions`, payload)
}

export async function articles_read(payload) {
  if (router.app.$route.query.source === "yunying") return
  return api.post(`/api/v1/mp/articles/read`, payload)
}

export async function get_simple_article(payload) {
  return api.get(`/api/v1/operation/articles/${payload}`, { params: payload })
}

// 评论统计
export async function get_comments_detail(payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         total_num1: 100,
  //         total_num2: 200,
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/operation/comments/statistic/${payload}`)
}

// 获取安泰im的授权码
export async function get_at_getToken(payload) {
  return api3rd.post("/szlhhh/auth/getToken", payload)
}

/**
 * 获取安泰小程序
 * @param {*} payload
 * @returns
 */
export async function get_at_scheme_link(payload) {
  return api3rd.post("/szlhhh/wx/getSchemeLink", payload)
}

// 回诊信息
export async function get_doctors_again(payload) {
  return api.get(`/api/v1/mp/articles/return-visit/doctors`, { params: payload })
}

//获取健康日志跳转信息
export async function get_log_jump_url() {
  return api.get(`/api/v1/mp/luohu/health_doc/encrypted_str`)
}

// 获取患者信息
export async function get_patient_info(payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         patient_name: '张三',
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/articles/to/patients`, { params: payload })
}

// msg_id换pack_id
export async function msg_to_pack_id(payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         pack_id: '112554444',
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/accompanies/session/pack`, { params: payload })
}

// 获取未读消息
export async function get_unread(payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         unread_count: 888,
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/accompanies/unread-counts`, { params: payload })
}

// 视频
export const get_video_list = async (params) => api.post(`/api/v1/operation/video/page`, params)

export const sendReadTime = (params) => api.post(`/api/v1/mp/articles/stays`, params)
